import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  DataGrid,
  DataGridStore,
  Header,
  Sort,
} from '@qbot-chat/qbot-uikit/grid';
import { confirmDialogStore } from '@qbot-chat/qbot-uikit';
import FrameWrap from '../../components/layout/FrameWrap';
import { makeObservable, observable } from 'mobx';
import { DatePicker } from 'antd';
import {
  Badge,
  Button,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Modal,
} from 'react-bootstrap';
import { Button as Refresh, Button as Download, Button as Play } from 'antd';
import {
  ReloadOutlined,
  DownloadOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import Panel from '../../components/layout/Panel';
import TitlePanel from '../../components/layout/TitlePanel';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { CHAT_CONTEXT_PATH, PHONE_CONTEXT_PATH } from '../../const/AppConst';
import _ from 'lodash';
import ModalBase from '../../components/modal/ModalBase';
import QPlayer from '../../components/player/QPlayer';

const axiosInstance = axios.create({
  baseURL: `${CHAT_CONTEXT_PATH}`,
  withCredentials: true,
});
const { RangePicker } = DatePicker;

const store = new DataGridStore({
  fetchUrl: `/record/list`,
  selectFnFromResponse: (res) => ({
    items: res.data.items,
    rowsCount: res.data.count,
  }),
  sort: new Sort(`startTime`, Sort.SORT_DIRECTION.DESC),
});

@observer
class VoiceRecordListView extends Component {
  @observable
  keyword = null;

  @observable
  startDate = dayjs();

  @observable
  endDate = dayjs();

  @observable
  isModal = false;

  @observable
  channelId = '';

  @observable
  callerNumber = '';

  constructor(props) {
    super(props);
    makeObservable(this);
    this.state = {
      isTable: false,
      excelSource: [],
      excelColumn: [
        {
          title: '기준일자',
          dataIndex: 'basDt',
          key: 'basDt',
        },
        {
          title: '티켓ID',
          dataIndex: 'ticketId',
          key: 'ticketId',
        },
        {
          title: '발신번호',
          dataIndex: 'callerNumber',
          key: 'callerNumber',
        },
        {
          title: '시작시각',
          dataIndex: 'startTime',
          key: 'startTime',
        },
        {
          title: '종료시각',
          dataIndex: 'endTime',
          key: 'endTime',
        },
        {
          title: '통화시간',
          dataIndex: 'callTime',
          key: 'callTime',
        },
        // {
        //   title: '070번호',
        //   dataIndex: 'trunkNumber',
        //   key: 'trunkNumber',
        // },
        {
          title: '착신여부',
          dataIndex: 'redirectTicket',
          key: 'redirectTicket',
        },
        {
          title: '착신 전화번호',
          dataIndex: 'redirectNumber',
          key: 'redirectNumber',
        },
      ],
    };
  }

  componentDidMount() {
    this.search();
    // store.refresh();
  }

  componentWillUnmount() {
    store.searchCondition.clear();
  }

  activeHandler = (value) => {
    this.keyword = value;
    this.startDate = value;
    this.endDate = value;
    this.search();
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      store.page = 1;
      this.search();
    }
  };

  search = () => {
    let obj = Object.fromEntries(store.searchCondition);
    store.searchCondition.set('keyword', this.keyword);
    store.searchCondition.set(
      'startDate',
      this.startDate && dayjs(this.startDate).startOf('day').format(),
    );
    store.searchCondition.set(
      'endDate',
      this.endDate && dayjs(this.endDate).startOf('day').format(),
    );
    if (!_.isEqual(obj, Object.fromEntries(store.searchCondition))) {
      store.refresh();
    } else {
      store.fetch();
    }
  };

  setDateSorting(dates) {
    if (dates[0]) this.startDate = dates[0];
    if (dates[1]) this.endDate = dates[1];
    this.search();
  }

  handleCloseModal = () => {
    this.isModal = false;
  };

  async downloadWav() {
    try {
      const response = await fetch(`${PHONE_CONTEXT_PATH}/${this.channelId}`, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Failed to download wav');
      }

      const blob = await response.blob();
      saveAs(blob, `${this.callerNumber}.wav`);
    } catch (e) {
      console.log('downloadWav Error ::', e);
    }
  }

  render() {
    const { excelColumn, excelSource, isTable } = this.state;
    return (
      <FrameWrap className="overflow-y-auto w-100">
        <TitlePanel>
          <FormGroup>
            <Col className="justify-content-between align-items-center d-flex">
              <TitlePanelLabel label={'녹취 목록'} />
              {/* <div>
                <Button
                  variant="outline-secondary"
                  onClick={() => navigate(`/main/voice/download`)}
                >
                  다운로드
                </Button>
              </div> */}
            </Col>
          </FormGroup>
        </TitlePanel>
        <Panel>
          <Panel style={{ border: '1px solid #E8E8E8', borderRadius: '.5rem' }}>
            <FormGroup>
              <Col className="d-flex flex-column">
                <div className="gap-3 d-flex align-items-center">
                  <Col className="gap-2 d-flex align-items-center">
                    <RangePicker
                      placeholder={['Start Date', 'End Date']}
                      size="large"
                      format={'YYYY-MM-DD'}
                      allowEmpty
                      value={[this.startDate, this.endDate]}
                      onChange={(dates) => {
                        this.setDateSorting(dates);
                      }}
                    />
                    <Refresh
                      shape="circle"
                      icon={<ReloadOutlined />}
                      onClick={() => this.activeHandler(null)}
                    />
                  </Col>
                  <Col>
                    <InputGroup>
                      <Form.Control
                        placeholder="내용 입력"
                        value={this.keyword || ''}
                        onChange={(e) => {
                          this.keyword = e.target.value;
                        }}
                        onKeyDown={(e) => this.onKeyDown(e)}
                      />
                      <Button
                        variant={'outline-secondary'}
                        onClick={() => this.search()}
                      >
                        검색
                      </Button>
                    </InputGroup>
                  </Col>
                </div>
              </Col>
            </FormGroup>
          </Panel>
        </Panel>
        <Panel className="flex-default">
          <DataGrid
            store={store}
            keyColumn="basDt"
            columns={[
              <Header
                id="basDt"
                key="basDt"
                name="기준일자"
                width={{ min: 80, current: 85, max: 120 }}
              />,
              <Header
                key="ticketId"
                id="ticketId"
                name="티켓 ID"
                width={{ min: 100, current: 150, max: 400 }}
                printFunction={(id) => (
                  <div className="d-flex">
                    <Badge pill bg="secondary" className="IdBadge">
                      {id}
                    </Badge>
                  </div>
                )}
              />,
              <Header
                id="callerNumber"
                key="callerNumber"
                sortable={true}
                name={`발신번호`}
                width={{ min: 100, current: 120, max: 150 }}
              />,
              <Header
                id="startTime"
                key="startTime"
                sortable={true}
                name={`시작시각`}
                width={{ min: 100, current: 110, max: 200 }}
                type="time"
              />,
              <Header
                id="endTime"
                key="endTime"
                sortable={true}
                name={`종료시각`}
                width={{ min: 100, current: 110, max: 200 }}
                type="time"
              />,
              <Header
                id="callTime"
                key="callTime"
                sortable={true}
                name={`통화시간`}
                width={{ min: 80, current: 110, max: 120 }}
              />,
              <Header
                id="recordFile"
                key="recordFile"
                name={`녹취파일`}
                width={{ min: 80, current: 85, max: 120 }}
                printFunction={(id, c) => (
                  <div className="d-flex">
                    <Play
                      type="text"
                      icon={<PlayCircleOutlined />}
                      // style={{ color: '#1890ff' }} // primary 색상 코드 사용
                      onClick={(e) => {
                        // e.stopPropagation(); // 이벤트 버블링 방지
                        this.channelId = c.ticketId; // 필요한 데이터 설정
                        this.callerNumber = c.callerNumber; // 필요한 데이터 설정
                        this.isModal = true;
                      }}
                    />
                    <Download
                      type="text"
                      icon={<DownloadOutlined />}
                      onClick={(e) => {
                        // e.stopPropagation(); // 이벤트 버블링 방지
                        this.channelId = c.ticketId; // 필요한 데이터 설정
                        this.callerNumber = c.callerNumber; // 필요한 데이터 설정
                        this.downloadWav();
                      }}
                    />
                  </div>
                )}
              />,
              // <Header
              //   id="trunkNumber"
              //   key="trunkNumber"
              //   sortable={true}
              //   name={`070번호`}
              //   width={{ min: 90, current: 100, max: 150 }}
              // />,
              <Header
                key="redirectTicket"
                id="redirectTicket"
                name="착신 여부"
                width={{ min: 70, current: 70, max: 130 }}
                printFunction={(isRedirect) => (
                  <Badge bg={isRedirect ? 'success' : 'danger'}>
                    {isRedirect ? 'O' : 'X'}
                  </Badge>
                )}
              />,
              <Header
                key="redirectNumber"
                id="redirectNumber"
                name="착신 전화번호"
                width={{ min: 90, current: 120, max: 150 }}
                type="phone"
              />,
            ]}
            onCellClick={(cell) => {
              if (cell.id == 'ticketId') {
                // navigate(`/main/ticket/detail/${cell.cellData}`);
              } else {
                this.channelId = cell.rowData.ticketId;
                this.callerNumber = cell.rowData.callerNumber;
              }
            }}
          />
        </Panel>
        <ModalBase
          centered
          show={this.isModal}
          onHide={this.handleCloseModal}
          backdrop
        >
          <Modal.Body>
            <QPlayer store={{ channelId: this.channelId }} />
          </Modal.Body>
        </ModalBase>
      </FrameWrap>
    );
  }
}

export {
  VoiceRecordListView as default,
  store as store,
  axiosInstance as axiosInstance,
};
