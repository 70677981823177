import React from 'react';
import Styles from './MessageInput.module.scss';
import { Button, Image, ProgressBar } from 'react-bootstrap';
import { MdClear } from 'react-icons/md';
import { CHAT_EVENT_TYPE, CONTENT_TYPE } from '../../const/ChatConst.js';
import { observer } from 'mobx-react';
import { debounce } from 'lodash';
import QuickMsg from './QuickMsg.js';
import {
  AddSquare,
  AttachSquare,
  CloseCircle,
  CloseSquare,
  Gallery,
  Send2,
} from 'iconsax-react';
import CustomButton from '../button/CustomButton.js';
import ContractionMessage from './message/ContractionMessage.js';
import CommonHelper from '../../helper/CommonHelper.js';
import { FileContent } from './message/FileContent.js';
import { Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import classNames from 'classnames';

const MIN_HEIGHT = 40;
const MAX_HEIGHT = 136;
const VC_CHAR_REGEX = /[ㄱ-ㅎㅏ-ㅣ]/g;
const SPECIAL_CHAR_REGEX = /^[^\w\s가-힣]+$/;

const QUICK_RESPONSE = [
  {
    value: '네 고객님',
    label: '네고객님',
  },
  {
    value: '확인 중 입니다',
    label: '확인중...',
  },
  {
    value: '잠시만 기다려주시겠습니까?',
    label: '잠시만기다려...',
  },
  {
    value: '기다려주셔서 감사합니다',
    label: '기다려감사...',
  },
  {
    value: '네 감사합니다',
    label: '네감사...',
  },
  {
    value: '바로 도움드리지 못해 죄송합니다',
    label: '바로도움...',
  },
];

@observer
class MessageInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      isSendError: false,
      scrollHeight: MIN_HEIGHT,
    };
  }

  imgRef;
  fileRef;

  componentDidMount() {
    const { store } = this.props;
    store.setQuickMsgFn(this.quickMsgSet);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.value !== this.state.value) {
      const elem = this.textareaRef;
      const scrollHeight =
        elem && this.state.value !== '' && elem.scrollHeight >= MIN_HEIGHT
          ? `${Math.min(Math.max(elem.scrollHeight, MIN_HEIGHT), MAX_HEIGHT)}px`
          : MIN_HEIGHT;

      this.setState({ scrollHeight }, () => {
        if (elem && document.activeElement !== elem) {
          elem.focus();
        }
      });
    }
  }

  onChange = (e) => {
    this.setState(
      {
        value: e.target.value,
        isSendError: false,
      },
      () => {
        this.typingEnd();
      },
    );
  };

  onSendMessage = async (value, type, file) => {
    let { store } = this.props;

    let message = {
      content: {
        type,
        value,
      },
      contentType: type,
      createdAt: dayjs(),
    };
    return (await store.onSendMessage) && store.onSendMessage(message, file);
  };

  debounceTypingStart = debounce(
    () => {
      let { store } = this.props;
      store.sendChatEvent(CHAT_EVENT_TYPE.TYPING_START);
    },
    200,
    { leading: true },
  );

  debounceQuickMsg = debounce(() => {
    const { store } = this.props;
    store.quickMsgStore.load(this.state.value);
  }, 200);

  quickMsgSet = (msg) => {
    this.setState({
      value: msg,
    });
  };

  typingEnd = () => {
    const { store } = this.props;
    if (store.quickMsgStore) {
      if (this.state.value.startsWith('#')) {
        if (!store.quickMsgStore.isOpen) {
          store.quickMsgStore.setIsOpen(true);
          console.log(store.quickMsgStore.isOpen);
        }
        this.debounceQuickMsg();
      } else {
        if (store.quickMsgStore.isOpen) {
          store.quickMsgStore.setIsOpen(false);
        }
      }
    }
    if (!this.state.value) {
      this.debounceTypingStart.cancel();
      store.sendChatEvent(CHAT_EVENT_TYPE.TYPING_END);
    } else {
      this.debounceTypingStart();
    }
  };

  send = (value, store) => {
    this.onSendMessage(value, CONTENT_TYPE.TEXT).then(() => {
      this.setState({ value: '', scrollHeight: MIN_HEIGHT }, () => {
        this.typingEnd();
      });
      store.debounceScrollToBottom();
      this.debounceTypingStart.cancel();
      store.clearReply();
      store.clearUploadFile();
    });
  };

  inputSend = (value, store) => {
    const { isUser } = this.props;
    if (!value.trim() && !store.uploadedFile) return;

    if (!isUser && VC_CHAR_REGEX.test(value)) {
      this.setState((prev) => ({
        isSendError: true,
        value: prev.value.replace(VC_CHAR_REGEX, ''),
      }));
      return;
    }
    if (!isUser && SPECIAL_CHAR_REGEX.test(value)) {
      this.setState((prev) => ({
        isSendError: true,
        scrollHeight: MIN_HEIGHT,
        value: '',
      }));
      return;
    }

    this.setState({
      isSendError: false,
    });
    this.send(value, store);
  };

  render() {
    let {
      store,
      isUser,
      isFileSendAble = false,
      isImageSendAble = false,
    } = this.props;

    return (
      <div className={`position-relative`}>
        <div className={Styles.UploadedFile}>
          {store.uploadedFile && (
            <>
              <div
                className={Styles.Reply}
                style={{
                  backgroundColor:
                    CONTENT_TYPE.IMAGE === store.uploadedType
                      ? 'var(--qt-ch-warning)'
                      : 'var(--qt-ch-active)',
                }}
              >
                <div className={`${Styles.Content} ${Styles.FileText}`}>
                  {store.uploadedType === CONTENT_TYPE.IMAGE ? (
                    <span>
                      <Gallery size={16} />
                    </span>
                  ) : (
                    <span>
                      <AttachSquare size={16} />
                    </span>
                  )}
                  <span>{store.uploadedFile.name}</span>
                  <span>
                    {CommonHelper.getByteSize(store.uploadedFile.size)}
                  </span>
                </div>
                <a
                  className={Styles.Clear}
                  onClick={() => {
                    store.clearUploadFile();
                  }}
                >
                  <MdClear size={24} />
                </a>
              </div>
              <div className={Styles.ImageContainer}>
                <div className={Styles.Image}>
                  {store.uploadedType === CONTENT_TYPE.IMAGE ? (
                    <Image
                      src={`${store.rsocketStore?.apiBaseUrl}/app/preview/${store?.uploadedFile?.url}`}
                      style={{
                        objectFit: 'contain',
                        height: 'auto',
                        width: 'auto',
                      }}
                    />
                  ) : (
                    <div
                      className={`d-flex justify-content-center align-items-center`}
                    >
                      <FileContent
                        value={store.uploadedFile}
                        store={store}
                        isBubble={true}
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <div className={`position-relative`}>
          {!isUser &&
            !store.replyMessage &&
            !store.isFileUploading &&
            !store.quickMsgStore.isOpen && (
              <div className={Styles.QuickButtonWrap}>
                {QUICK_RESPONSE.map((v) => (
                  <Tooltip key={v.label} title={v.value}>
                    <Tag onClick={() => this.send(v.value, store)}>
                      {v.label}
                    </Tag>
                  </Tooltip>
                ))}
              </div>
            )}
          {store.replyMessage && (
            <div className={Styles.Reply}>
              <div>
                <div className={Styles.Name}>
                  {store.replyMessage.sender.name} 님에게 답장
                </div>
                <div className={Styles.Content}>
                  <ContractionMessage message={store.replyMessage} />
                </div>
              </div>
              <a
                className={Styles.Clear}
                onClick={() => {
                  store.clearReply();
                }}
              >
                <CloseCircle />
              </a>
            </div>
          )}
          {store.quickMsgStore && <QuickMsg store={store} />}
        </div>
        {store.isFileUploading && (
          <ProgressBar
            now={store.progress}
            variant={
              CONTENT_TYPE.IMAGE === store.uploadedType ? 'warning' : 'info'
            }
            animated
            style={{
              height: 3,
              borderRadius: 0,
              zIndex: 2,
              position: 'relative',
            }}
          />
        )}
        <div
          className={classNames(
            Styles.MessageInput,
            this.state.isSendError && Styles.isError,
          )}
        >
          <div className={Styles.MessageInputContent}>
            {(isFileSendAble || isImageSendAble) && (
              <div className={Styles.OptionOpener}>
                <CustomButton
                  className={Styles.ButtonWrapper}
                  disabled={!store.channelId}
                  onClick={() => {
                    store.isOptionOpen = !store.isOptionOpen;
                  }}
                >
                  {store.isOptionOpen ? (
                    <CloseSquare size={28} />
                  ) : (
                    <AddSquare size={28} />
                  )}
                </CustomButton>
              </div>
            )}

            <textarea
              className={Styles.TextArea}
              rows={1}
              maxLength={5000}
              placeholder={`내용을 입력해 주세요.`}
              value={this.state.value}
              onChange={this.onChange}
              style={{ height: this.state.scrollHeight }}
              disabled={!store.channelId}
              ref={(ref) => (this.textareaRef = ref)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  if (!store.isSendAble) return;
                  if (e.nativeEvent.isComposing) return;
                  e.preventDefault();
                  this.inputSend(this.state.value, store);
                }
              }}
            />
            <CustomButton
              className={`${Styles.SendButton} ${Styles.ButtonWrapper}`}
              style={
                !this.state.value || !store.uploadedFile
                  ? {}
                  : { cursor: 'not-allowed' }
              }
              disabled={
                !store.channelId ||
                (!this.state.value.trim() && !store.uploadedFile)
              }
              onClick={() => {
                this.inputSend(this.state.value, store);
              }}
            >
              <Send2
                size={28}
                // style={{ opacity: this.state.value.trim() ? 1 : 0.5 }}
                style={{
                  color: this.state.isSendError
                    ? 'var(--qt-ch-danger)'
                    : 'inherit',
                }}
              />
            </CustomButton>
          </div>
          {(isImageSendAble || isFileSendAble) && (
            <div
              className={Styles.Option}
              style={{ height: store.isOptionOpen ? '6.5rem' : 0 }}
            >
              {isImageSendAble && (
                <div
                  className={`${Styles.OptionWrap} text-center `}
                  onClick={() => {
                    this.imgRef.click();
                  }}
                >
                  <input
                    type={'file'}
                    ref={(ref) => (this.imgRef = ref)}
                    className={`d-none`}
                    accept="image/*"
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        const formData = new FormData();
                        formData.append('file', e.target.files[0]);
                        store.uploadedType = CONTENT_TYPE.IMAGE;
                        store.uploadFile(formData).then((media) => {
                          this.imgRef.value = '';
                          store.uploadedFile = media;
                        });
                      }
                    }}
                  />
                  <Button
                    className={Styles.Icon}
                    style={{ backgroundColor: `var(--qt-ch-warning)` }}
                  >
                    <Gallery color={`var(--qt-gray1)`} size={'2rem'} />
                  </Button>
                  <div style={{ color: 'var(--qt-cool-gray7)' }}>앨범</div>
                </div>
              )}
              {isFileSendAble && (
                <div
                  className={`${Styles.OptionWrap} text-center`}
                  onClick={() => {
                    this.fileRef.click();
                  }}
                >
                  <input
                    type={'file'}
                    ref={(ref) => (this.fileRef = ref)}
                    className={`d-none`}
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        const formData = new FormData();
                        formData.append('file', e.target.files[0]);
                        store.uploadedType = CONTENT_TYPE.FILE;
                        store.uploadFile(formData).then((media) => {
                          this.fileRef.value = '';
                          store.uploadedFile = media;
                        });
                      }
                    }}
                  />
                  <Button
                    className={Styles.Icon}
                    style={{ backgroundColor: `var(--qt-primary2)` }}
                  >
                    <AttachSquare color={'var(--qt-gray1)'} size={'2rem'} />
                  </Button>
                  <div style={{ color: 'var(--qt-cool-gray7)' }}>파일</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export { MessageInput };
