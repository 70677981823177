import React from 'react';
import { observer } from 'mobx-react';

import {
  Button,
  Col,
  FormControl,
  FormGroup,
  InputGroup,
} from 'react-bootstrap';
import {
  DataGrid,
  DataGridStore,
  Header,
  Sort,
} from '@qbot-chat/qbot-uikit/grid';
import BranchMemoDialog, { branchMemoDialogStore } from './BranchMemoDialog';
import Panel from '../../components/layout/Panel';
import TitlePanel from '../../components/layout/TitlePanel';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import * as PropTypes from 'prop-types';
import Frame from '../../components/layout/Frame';
import FrameWrap from '../../components/layout/FrameWrap';
import Avatar from '../../components/avatar/Avatar';
import { ACCESS_RIGHTS } from '../../const/AppConst';
import { USER_TYPE } from '@qbot-chat/qbot-uikit';
import Select from 'react-select';
import appStore from '../../store/AppStore';
import dayjs from 'dayjs';

const dataGridStore = new DataGridStore({
  fetchUrl: '/branch/pin/memos',
  selectFnFromResponse: (res) => ({
    items: res.data.items,
    rowsCount: res.data.count,
  }),
  sort: new Sort(`id`, Sort.SORT_DIRECTION.DESC),
  // noHeader: true,
});

dataGridStore.searchCondition.set('searchType', 'BRANCH');

function TitlePanelL() {
  return null;
}

TitlePanelL.propTypes = { label: PropTypes.string };

@observer
class BranchMemoView extends React.Component {
  componentDidMount() {
    dataGridStore.refresh();
  }

  componentWillUnmount() {
    dataGridStore.searchCondition.clear();
  }

  render() {
    let searchCondition = dataGridStore.searchCondition;
    return (
      <Frame>
        <FrameWrap>
          <TitlePanel>
            <FormGroup>
              <Col
                className={`justify-content-between align-items-center d-flex`}
              >
                <TitlePanelLabel label={'지점공감'} />
                {ACCESS_RIGHTS[USER_TYPE.AGENT_LEADER].includes(
                  appStore.type,
                ) && (
                  <Button
                    variant={`outline-primary`}
                    onClick={() => {
                      branchMemoDialogStore.open(undefined, () =>
                        dataGridStore.refresh(),
                      );
                    }}
                  >
                    + 새 공감 생성
                  </Button>
                )}
              </Col>
            </FormGroup>
          </TitlePanel>
          <Panel>
            <Panel
              style={{ border: '1px solid #E8E8E8', borderRadius: '.5rem' }}
            >
              <FormGroup>
                <Col className={`d-flex flex-column`}>
                  <div className={'d-flex justify-content-between'}>
                    <Col md={3}>
                      <InputGroup className={`w-100`}>
                        <InputGroup.Text>유형</InputGroup.Text>
                        <Select
                          className={'flex-grow-1'}
                          menuPortalTarget={document.body}
                          menuShouldBlockScroll={true}
                          isClearable={true}
                          onChange={(option) => {
                            if (option) {
                              dataGridStore.searchCondition.set(
                                'searchType',
                                option.value,
                              );
                              dataGridStore.refresh();
                            } else {
                              dataGridStore.searchCondition.set(
                                'searchType',
                                null,
                              );
                              dataGridStore.refresh();
                            }
                          }}
                          placeholder={'전체'}
                          options={[
                            { value: 'BRANCH', label: '점포' },
                            { value: 'KEYWORD', label: '내용' },
                          ]}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minWidth: '13rem',
                              borderRadius: '0 4px 4px 0',
                            }),
                          }}
                        ></Select>
                      </InputGroup>
                    </Col>
                    <Col md={{ span: 8, offset: 1 }}>
                      <InputGroup>
                        <FormControl
                          placeholder={'검색어 입력'}
                          value={searchCondition.get('keyword') || ''}
                          onChange={(e) => {
                            searchCondition.set('keyword', e.target.value);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') dataGridStore.refresh();
                          }}
                        />
                        <Button
                          variant={`outline-secondary`}
                          onClick={() => dataGridStore.refresh()}
                        >
                          검색
                        </Button>
                      </InputGroup>
                    </Col>
                  </div>
                </Col>
              </FormGroup>
            </Panel>
          </Panel>
          <Panel className={'flex-default'}>
            <DataGrid
              store={dataGridStore}
              columns={[
                <Header
                  key="id"
                  id={`index`}
                  name={`번호`}
                  width={{ min: 40, current: 50, max: 60 }}
                />,
                <Header
                  key="type"
                  id={`type`}
                  name={`게시유형`}
                  width={{ min: 70, current: 70, max: 100 }}
                  printFunction={(type) =>
                    type === 'pin'
                      ? '점포별'
                      : type === 'pin_brand'
                        ? '브랜드별'
                        : ''
                  }
                />,
                <Header
                  key="pinBranches"
                  id={`pinBranches`}
                  name={`점포`}
                  width={{ min: 150, current: 200, max: 500 }}
                  printFunction={(branches, row) =>
                    row.type === 'pin' ? (
                      <div>
                        {branches?.length < 2
                          ? branches?.[0]?.name
                          : `${branches?.[0]?.name} 외 ${branches?.length - 1} `}
                      </div>
                    ) : (
                      <div>
                        {row?.pinBrands?.length < 2
                          ? row?.pinBrands?.[0]?.name
                          : `${row?.pinBrands?.[0]?.name} 외 ${row?.pinBrands?.length - 1} `}
                      </div>
                    )
                  }
                />,
                <Header
                  key="notes"
                  id={`notes`}
                  name={`내용`}
                  width={{ min: 150, current: 200, max: 500 }}
                  printFunction={(answer) => <div>{answer}</div>}
                />,
                <Header
                  key="beginAt"
                  id={`beginAt`}
                  name={`시작일`}
                  sortable={true}
                  className={`justify-content-end`}
                  width={{ min: 80, current: 100, max: 100 }}
                  printFunction={(beginAt) =>
                    beginAt && dayjs(beginAt).format('YYYY-MM-DD')
                  }
                />,

                <Header
                  key="untilAt"
                  id={`untilAt`}
                  name={`종료일`}
                  sortable={true}
                  className={`justify-content-end`}
                  width={{ min: 80, current: 100, max: 100 }}
                  printFunction={(untilAt) =>
                    untilAt && dayjs(untilAt).format('YYYY-MM-DD')
                  }
                />,
                <Header
                  id={`createdAt`}
                  key={`createdAt`}
                  name={`생성일시`}
                  sortable={true}
                  width={{ min: 100, current: 180, max: 250 }}
                  type="datetime"
                />,
                <Header
                  id={`creator`}
                  key={`creator`}
                  name={`생성자`}
                  width={{ min: 36, current: 160, max: 200 }}
                  printFunction={(creator) => creator?.name}
                />,
                <Header
                  id={`updatedAt`}
                  key={`updatedAt`}
                  name={`수정일시`}
                  sortable={true}
                  width={{ min: 100, current: 180, max: 250 }}
                  type="datetime"
                />,
                <Header
                  id={`updater`}
                  key={`updater`}
                  name={`수정자`}
                  width={{ min: 36, current: 160, max: 200 }}
                  printFunction={(updater) => updater?.name}
                />,
              ]}
              keyColumn="id"
              highlightClass="table-primary"
              onRowClick={(rowData) => {
                branchMemoDialogStore.open(rowData.id, () =>
                  dataGridStore.fetch(),
                );
              }}
            />
          </Panel>
          <BranchMemoDialog />
        </FrameWrap>
      </Frame>
    );
  }
}

export default BranchMemoView;
