import React, { Component } from 'react';
import { action, makeObservable, observable } from 'mobx';
import { FormStore, Input, Validation } from '../../components/validation';
import axios from 'axios';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import { confirmDialogStore } from '@qbot-chat/qbot-uikit';
import { Warning2 } from 'iconsax-react';
import ModalBase from '../../components/modal/ModalBase';
import Styles from './RankView.module.scss';
import {
  Col,
  Modal,
  Form,
  InputGroup,
  Row,
  Button,
  Spinner,
} from 'react-bootstrap';
import dayjs from 'dayjs';

class RankDialogStore {
  @observable
  show = false;

  id;

  onSave;

  rankFormStore = new FormStore();

  @observable
  isSaving = false;

  constructor() {
    makeObservable(this);
  }

  @action.bound
  open(id, onSave) {
    this.id = id;
    this.onSave = onSave;

    if (id) {
      axios.post(`/rank/detail/${id}`).then(({ data }) => {
        this.rankFormStore.values = data;
      });
    } else {
      this.rankFormStore.clear();
    }
    this.show = true;
  }

  @action.bound
  close() {
    this.show = false;
    this.id = undefined;
    this.rankFormStore.clear();
  }

  @action.bound
  delete() {
    confirmDialogStore.openRemove(<Warning2 />, `삭제 하시겠습니까?`, () => {
      axios.post(`/rank/delete`, { ...this.rankFormStore.values }).then(() => {
        toast.info('직급을 삭제했습니다.');
        this.onSave && this.onSave();
        this.close();
      });
    });
  }

  @action.bound
  save() {
    if (!this.rankFormStore.doCheckValid()) {
      return;
    }

    this.isSaving = true;

    let url = this.id ? `/rank/update` : `/rank/insert`;

    axios
      .post(url, { ...this.rankFormStore.values })
      .then(() => {
        toast.info('직급을 저장하였습니다.');
        this.onSave && this.onSave();
      })
      .finally(() => {
        this.close();
        this.isSaving = false;
      });
  }
}

const rankDialogStore = new RankDialogStore();

@observer
class RankDialog extends Component {
  handleClose = () => {
    rankDialogStore.close();
  };
  render() {
    const { id, show, rankFormStore } = rankDialogStore;
    const { createdAt, updatedAt } = rankFormStore.values;
    return (
      <ModalBase show={show} size={'lg'} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <div className={Styles.Header}>
            {id ? '직급 수정' : '새 직급 작성'}
          </div>
        </Modal.Header>
        <Modal.Body className={Styles.Body}>
          <Form.Group as={Row} className="mb-4">
            <Col
              md={2}
              sm={2}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              라벨
            </Col>
            <Col md={4} sm={4}>
              <Input
                store={rankFormStore}
                name="label"
                size="md"
                validations={[new Validation(/^.+$/, '라벨을 입력해주세요.')]}
                maxLength={100}
                postfix={
                  <InputGroup.Text>
                    <Form.Text muted>
                      {rankFormStore.valueMap.get('label')?.length || 0}/100
                    </Form.Text>
                  </InputGroup.Text>
                }
              />
            </Col>
            <Col
              md={2}
              sm={2}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              수용가능티켓
            </Col>
            <Col md={4} sm={4}>
              <Input
                store={rankFormStore}
                label={`최대티켓수량`}
                name={`maxCapacity`}
                type={`number`}
                validations={[
                  new Validation(/^.+$/, '최대티켓수량을 입력해주세요.'),
                ]}
                max={20}
                min={1}
                validOnBlur
              />
            </Col>
          </Form.Group>
          {id && (
            <>
              <Form.Group as={Row} className="mb-4">
                <Col md={2} sm={2}>
                  <Form.Label>작성자</Form.Label>
                </Col>
                <Col md={4} sm={4}>
                  <div>{rankFormStore.valueMap.get('creator.name')}</div>
                </Col>
                <Col md={2} sm={2}>
                  <Form.Label>수정자</Form.Label>
                </Col>
                <Col md={4} sm={4}>
                  <div>{rankFormStore.valueMap.get('updater.name')}</div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-4">
                <Col md={2} sm={2}>
                  <Form.Label>생성일</Form.Label>
                </Col>
                <Col md={4} sm={4}>
                  <div>
                    {createdAt && dayjs(createdAt).format('YYYY-MM-DD')}
                  </div>
                </Col>
                <Col md={2} sm={2}>
                  <Form.Label>수정일</Form.Label>
                </Col>
                <Col md={4} sm={4}>
                  <div>
                    {updatedAt && dayjs(updatedAt)?.format('YYYY-MM-DD')}
                  </div>
                </Col>
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {id && (
            <div className="flex-grow-1">
              <Button variant="danger" onClick={rankDialogStore.delete}>
                삭제
              </Button>
            </div>
          )}

          <Button variant="secondary" onClick={rankDialogStore.close}>
            닫기
          </Button>
          <Button
            variant="primary"
            style={{ color: 'white' }}
            onClick={rankDialogStore.save}
            disabled={rankDialogStore.isSaving}
          >
            {rankDialogStore.isSaving ? <Spinner /> : '저장'}
          </Button>
        </Modal.Footer>
      </ModalBase>
    );
  }
}

export { RankDialog as default, rankDialogStore };
