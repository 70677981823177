import React, { useState, useEffect, useRef } from 'react';
import { PHONE_CONTEXT_PATH } from '../../const/AppConst';
import Styles from './QPlayer.module.scss';
import { FaRegStopCircle } from 'react-icons/fa';
import { FaRegCirclePlay } from 'react-icons/fa6';

const QPlayer = ({ store = null }) => {
  const [audioSrc, setAudioSrc] = useState('');
  const [lastPlayedTime, setLastPlayedTime] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const audioRef = useRef(null);
  const interval = useRef(null);
  const canplay = useRef(false);
  const isFirst = useRef(false);
  const prevPrevAudioSize = useRef(0);
  const prevAudioSize = useRef(0);
  const ticketId = store?.channelId;
  const isTicketActive = store?.channel?.status === 'ACTIVE';
  const fetchAudio = async () => {
    if (!store) return;
    try {
      // audio src가 변경되어도 원래 재생하던 위치에서 플레이되도록...
      setLastPlayedTime(audioRef.current?.currentTime ?? 0);
      // 전화번호 부분은 props으로 받아와서 사용하세용
      const response = await fetch(`${PHONE_CONTEXT_PATH}/${ticketId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch audio');
      }
      const blob = await response.blob();
      const audioUrl = URL.createObjectURL(blob);

      if (!isFirst.current) {
        // 최초 요청 시 오디오 size 저장
        setAudioSrc(audioUrl);
        isFirst.current = true;
        prevAudioSize.current = blob.size;
      } else {
        if (
          prevAudioSize.current !== prevPrevAudioSize.current ||
          prevAudioSize.current !== blob.size ||
          blob.size < 50
        ) {
          // 새로운 오디오가 있을 경우에만 audio src 변경
          setAudioSrc(audioUrl);
          prevPrevAudioSize.current = prevAudioSize.current;
          prevAudioSize.current = blob.size;
        } else {
          console.log('같은 사이즈 3번 이상 시 audio 업데이트 하지 않음');
          onStopPolling();
        }
      }
    } catch (error) {
      console.error('Error fetching audio:', error.message);
    }
  };

  useEffect(() => {
    if (!ticketId) return;
    onReset();
    fetchAudio();
    if (isTicketActive) {
      isFirst.current = false;
      onPolling();
    }
    return () => {
      onReset();
    };
  }, [ticketId, isTicketActive]);

  const onStopPolling = () => {
    setIsFetching(false);
    isFirst.current = false;
    clearInterval(interval.current);
  };

  const onReset = () => {
    setLastPlayedTime(0);
    setAudioSrc('');
    prevAudioSize.current = 0;
    prevPrevAudioSize.current = 0;
    canplay.current = false;
    onStopPolling();
  };

  const onPolling = () => {
    // 버벅임에 정도 따라 인터벌시간 변경해도됩니다.
    setIsFetching(true);
    interval.current = setInterval(() => {
      fetchAudio();
    }, 1200);
  };

  const onClickStop = () => {
    audioRef.current?.pause();
    onStopPolling();
  };

  useEffect(() => {
    if (!audioRef.current || !audioSrc) return;
    audioRef.current.load();
    if (lastPlayedTime != 0 && lastPlayedTime >= audioRef.current.duration) {
      // 전부 재생, 더 받아오지 않는다고 판단되는 경우 interval clear해도됨
      console.log('마지막음성 ' + lastPlayedTime);
    } else {
      if (!!canplay.current) {
        audioRef.current.currentTime = lastPlayedTime;
        if (audioRef.current.paused) audioRef.current.play();
      }
    }
  }, [audioSrc]);

  // const handleAudioEnded = () => {
  //   console.log('handleAudioEnded');
  //   if (audioRef.current) {
  //     setLastPlayedTime(audioRef.current?.currentTime);
  //   }
  //   // }
  // };
  const handleCheckCanPlay = () => {
    canplay.current = true;
    if (
      audioRef.current.duration > 0 &&
      audioRef.current.paused &&
      audioRef.current.currentTime > 0
    ) {
      audioRef.current.play();
    }
  };
  //  onEnded = { handleAudioEnded };
  return (
    <div className={Styles.QPlayWrapper}>
      <audio
        controls
        controlsList="nodownload"
        ref={audioRef}
        onCanPlay={handleCheckCanPlay}
        loop={false}
        style={{ width: '100%' }}
      >
        <source src={audioSrc} type="audio/wav" />
      </audio>
      {isTicketActive && isFetching && (
        <button className={Styles.Button} onClick={onClickStop}>
          <FaRegStopCircle color="var(--qt-ch-danger)" />
        </button>
      )}
      {isTicketActive && !isFetching && (
        <button className={Styles.Button} onClick={onPolling}>
          <FaRegCirclePlay color="var(--qt-ch-success)" />
        </button>
      )}
    </div>
  );
};
export default QPlayer;
