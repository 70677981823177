import ReactGA from 'react-ga4';
import { format } from 'date-fns';
import { APP_CONTEXT_PATH } from '../const/AppConst';
import dayjs from 'dayjs';

export const eventTrack = (userId, category, action) => {
  const trackingId = process.env.REACT_APP_GA_TRACKING_ID;

  const now = new Date();
  const date = format(now, 'yyyyMMdd');
  const time = format(now, 'HHmmSS');
  const param = Object.assign(
    {},
    {
      category,
      action,
    },
    {
      'log_type ': 'BO',
      key1_timestamp_yyyymmdd: date,
      key2_timestamp_hh24miss: time,
      key4_userid: userId, //사용자아이디
    },
  );

  if (trackingId) {
    ReactGA.event(action, param);
  } else {
    console.log(param);
  }
};

export const makeEventTrackFn = (userId) => (category, action) => {
  eventTrack(userId, category, action);
};

export const fromNow = (date) => {
  if (date) {
    const lastMessageAt = dayjs(date);
    const today = dayjs();
    const yesterday = today.subtract(1, 'day');

    if (lastMessageAt.isSame(today, 'day')) {
      return lastMessageAt.format('a hh:mm'); // 오전/오후 hh:mm
    } else if (lastMessageAt.isSame(yesterday, 'day')) {
      return '어제';
    } else {
      return lastMessageAt.format('MMM DD'); // 월 일 형식
    }
  } else {
    return '';
  }
};

export const getPreviewUrl = (id) => `${APP_CONTEXT_PATH}/upload/${id}/preview`;
