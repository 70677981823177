export const CHART_TOOLBAR_OPTION = {
  show: true,
  tools: {
    download: false,
  },
};


export const SORT_ENUM = {
  ascend: 'ASC',
  descend: 'DESC',
};