import React, { Suspense, useEffect, useState } from 'react';
import Styles from './TicketDetailView.module.scss';
import appStore from '../../store/AppStore';
import { observer } from 'mobx-react';
import { ticketStore } from '../../store/AdminChatStore';

const COMPANY_ENUM = {
  2: 'FydFrame',
};

const TicketDetailView = observer(({ store, ticket }) => {
  const [ErpComponent, setErpComponent] = useState(null);
  const companyId = String(appStore.companyId);
  const isFinished =
    ticketStore?.ticketMap.get(ticket?.id)?.isFinished ?? false;

  useEffect(() => {
    store.setChannel(ticket);
  }, [isFinished]);

  useEffect(() => {
    if (!companyId || !Object.keys(COMPANY_ENUM).includes(companyId)) return;

    import(`../../components/erp`)
      .then((module) => {
        setErpComponent(() => module[COMPANY_ENUM[companyId]]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [companyId]);

  if (!ErpComponent) return;
  return (
    <div className={Styles.TicketDetail}>
      <Suspense fallback="Loading...">
        <ErpComponent
          ticket={ticket}
          isFinished={isFinished}
          store={store}
          companyId={companyId}
        />
      </Suspense>
    </div>
  );
});

TicketDetailView.propTypes = {};

export default TicketDetailView;
