import React from 'react';
import Styles from './Channel.module.scss';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ChatStore } from '../../store/ChatStore.js';
import { CHAT_EVENT_TYPE, TICKET_STATUS } from '../../const/ChatConst.js';
import { Badge, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import ContractionMessage from '../chat/message/ContractionMessage.js';
import ContractionMessageErrorBoundary from '../chat/message/ContractionMessageErrorBoundary.js';
import { Copy } from 'iconsax-react';
import { NotificationManager } from 'react-notifications';
import { MdCancel } from 'react-icons/md';
import dayjs from 'dayjs';

@observer
class Channel extends React.Component {
  handleCopyClipBoard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      NotificationManager.info('클립보드에 복사되었습니다.');
    } catch (e) {
      NotificationManager.info('복사에 실패하였습니다');
    }
  };

  render() {
    let {
      store,
      channel,
      onChannelClick,
      // location,
      isAgent = false,
      renderLabel,
      onCloseCallback,
      activeChannelId,
      isKeyword,
      isSummary,
    } = this.props;
    let {
      id,
      // createdAt,
      lastMessage,
      isConfirm = true,
      notConfirmCount = 0,
      isFinished = false,
      finishedType,
      assignment,
      keywords,
      summary,
      // customer,
    } = channel;

    return (
      <div className={`position-relative`}>
        <div
          className={`${Styles.ChannelBox} ${!isConfirm && isAgent ? Styles.On : ''} ${id === activeChannelId ? Styles.Active : ''}`}
          onClick={() => {
            if (activeChannelId === id) return;
            if (channel?.status != TICKET_STATUS.CLOSED) {
              store.enterChannel(channel).then(() => {
                onChannelClick && onChannelClick(channel);
              });
            } else {
              onChannelClick && onChannelClick(channel);
            }
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            store.openDropDown(channel, e.pageX, e.pageY);
          }}
        >
          <div className={Styles.ChannelRoom}>
            <div className={Styles.RoomDec}>
              {renderLabel && renderLabel(channel)}
            </div>
            <span className={Styles.RoomCreatedAt}>
              {dayjs(lastMessage?.createdAt).fromNow()}
            </span>
          </div>

          <div className={Styles.ChannelId}>
            {assignment?.reason && (
              <OverlayTrigger
                placement="bottom"
                overlay={(props) => (
                  <Popover {...props}>
                    <Popover.Body>{assignment?.reason}</Popover.Body>
                  </Popover>
                )}
              >
                {({ ref, ...triggerHandler }) => (
                  <Badge bg={'warning'} ref={ref} {...triggerHandler}>
                    이관
                  </Badge>
                )}
              </OverlayTrigger>
            )}

            <div className={Styles.TicketId}>
              {isSummary && summary ? summary : channel.customer.name}
            </div>

            <Copy
              size="16"
              className={Styles.CopyButton}
              onClick={(e) => {
                e.stopPropagation();
                this.handleCopyClipBoard(channel.id);
              }}
            />
          </div>

          <div className={Styles.ChannelDec}>
            {lastMessage && (!isKeyword || !keywords) && (
              <div className={Styles.Message}>
                <ContractionMessageErrorBoundary>
                  <ContractionMessage message={lastMessage} />
                </ContractionMessageErrorBoundary>
              </div>
            )}

            {isKeyword && keywords && (
              <ul className={Styles.TicketTagList}>
                {keywords.map((v, i) => (
                  <li key={i}>{v}</li>
                ))}
              </ul>
            )}

            {notConfirmCount > 0 && (
              <div className={`${Styles.NewMessageNum}`}>
                {notConfirmCount > 9 ? '9+' : notConfirmCount}
              </div>
            )}
          </div>
        </div>
        {isFinished && (
          <div className={Styles.BackDrop}>
            <div className={Styles.CloseDialog}>
              <div className={Styles.Title}>
                {finishedType == CHAT_EVENT_TYPE.CLOSE ? `종료` : '이관'}된 티켓
                <div className={Styles.Content}>{channel.customer.name}</div>
              </div>
              <div className={Styles.Button}>
                <Button
                  variant={`light`}
                  size={`lg`}
                  onClick={() => {
                    store.deleteTicket(id);
                    onCloseCallback && onCloseCallback(channel);
                  }}
                >
                  <MdCancel />
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

Channel.propType = {
  store: PropTypes.instanceOf(ChatStore),
};

export { Channel };
