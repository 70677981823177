import { computed, makeObservable, observable } from 'mobx';
import axios from 'axios';
import dayjs from 'dayjs';

class SettingStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  isLoadedSetting = false;

  @observable
  ticketEventMap = new Map();

  fetchTicketEvent = () => {
    let store = this;
    axios.post('/ticket/event/all').then((r) => {
      store.ticketEventMap.replace(r.data.map((event) => [event.type, event]));
    });
  };

  @observable
  workSheet;

  @computed
  get weekTimes() {
    return this.workSheet?.weekTimes || [];
  }

  fetchWorkSheet(team) {
    let store = this;
    axios.post(`/setting/work/sheet`, team).then((res) => {
      store.workSheet = res.data;
    });
  }

  @observable
  setting = {
    timezone: 'Asia/Seoul',
    isTransferableByAgent: false,
    isCloseableByAgent: false,
    isSendableCloseMessage: false,
    isEvaluationableByCustomer: false,
    isCloseMessageNoReactAutoClose: false,
    chatRatingMessage: '',
    chatClosedMessage: '',
    chatRatingClosedMessage: '',
    defaultGreetingMessage: '',
  };

  fetchSettingInfo() {
    let store = this;
    axios.post('/setting/info', {}).then((res) => {
      let { timezone } = res.data;
      this.setting = res.data;
      timezone && dayjs.tz.setDefault(timezone);
      store.isLoadedSetting = true;
    });
  }
}

const settingStore = new SettingStore();

export { settingStore as default };
