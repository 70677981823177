import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Styles from './SettingManageView.module.scss';
import Panel from '../../components/layout/Panel';
import { Button, Col, Collapse, FormGroup, Row } from 'react-bootstrap';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import TitlePanel from '../../components/layout/TitlePanel';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import settingStore from '../../store/SettingStore';
import SettingMessageEditor from './ticket_event/SettingMessageEditor';
@observer
class GeneralManageView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timezone: undefined,
    };
  }

  render() {
    let originTimezone = this.state.timezone || settingStore?.setting?.timezone;
    let { setting } = settingStore;
    return (
      <div className={`${Styles.Tab} h-100 flex-default`}>
        <TitlePanel>
          <TitlePanelLabel label={'일반 설정 - 일반'} />
        </TitlePanel>
        <div className={'flex-grow-1 overflow-y-auto'}>
          <Panel>
            <Panel className={`${Styles.PanelBorder} mb-3`}>
              <div className={Styles.TicketEventEditor}>
                <FormGroup as={Row} className={`w-100`}>
                  <Col md={6}>
                    <div className={`${Styles.Title} ${Styles.Label}`}>
                      <div className={`d-flex`}>표준 시간대</div>
                    </div>
                    <div className={Styles.Description}>
                      선택한 표준 시간대를 기준으로 티켓이 생성, 배정, 이관
                      그리고 종료될 때의 일시를 기록하고 보여줍니다.
                    </div>
                  </Col>
                  <Col
                    md={6}
                    className={`d-flex flex-column align-items-start justify-content-center`}
                  >
                    {/* ▼ API 테스트 동안 주석처리 후 디버깅 예정(해당 component에서 Error 발생으로 화면이 나오지 않아 주석처리) */}
                    {/* <div>
                                            <div className={`${Styles.SubTitle}`}>{dayjs(appStore.now).tz(originTimezone).format('llll')}</div>
                                        </div>
                                        <TimezoneSelect className={`w-100`}
                                                        isSearchable={true}
                                                        menuPortalTarget={document.body}
                                                        styles={{
                                                            menuPortal: base => ({
                                                            ...base,
                                                            zIndex: '999'
                                                        })}}
                                                        value={originTimezone}
                                                        onChange={(e)=>{
                                                            this.setState({timezone:e.value})
                                                        }}
                                        /> */}
                  </Col>
                </FormGroup>
                <Collapse
                  in={originTimezone !== settingStore.setting?.timezone}
                >
                  <div className={`${Styles.ButtonContainer}`}>
                    <Button
                      variant={'outline-secondary'}
                      className={Styles.Button}
                      onClick={() => this.setState({ timezone: undefined })}
                    >
                      취소
                    </Button>
                    <Button
                      variant={'outline-primary'}
                      className={Styles.Button}
                      onClick={() => {
                        axios
                          .post(`/setting/update`, {
                            ...settingStore.setting,
                            timezone: this.state.timezone,
                          })
                          .then(() => {
                            settingStore.fetchSettingInfo();
                            NotificationManager.success(`업데이트 완료`);
                          });
                      }}
                    >
                      저장
                    </Button>
                  </div>
                </Collapse>
              </div>
            </Panel>
            <Panel className={`${Styles.PanelBorder} mb-3`}>
              <SettingMessageEditor
                id={`defaultGreetingMessage`}
                title={`기본 인사말`}
                description={`상담사와 챗봇 인사말이 비어 있을 경우 대체하는 메시지`}
                message={setting?.defaultGreetingMessage}
                isUseMention={true}
              />
            </Panel>
            <Panel className={`${Styles.PanelBorder} mb-3`}>
              <SettingMessageEditor
                id={`chatClosedMessage`}
                title={`상담 종료 메시지`}
                description={`고객이 상담 종료 화면에서 볼 수 있는 메시지`}
                message={setting?.chatClosedMessage}
              />
            </Panel>
            <Panel className={`${Styles.PanelBorder} mb-3`}>
              <SettingMessageEditor
                id={`chatRatingClosedMessage`}
                title={`평가 후 상담 종료 메시지`}
                description={`고객이 평가 후 상담 종료 화면에서 볼 수 있는 메시지`}
                message={setting?.chatRatingClosedMessage}
              />
            </Panel>
          </Panel>
        </div>
      </div>
    );
  }
}

export default GeneralManageView;
